@import "../../styles/mixins";

// .alerts-page {
//     height: 100%;
// }
// .dialog-content {
//     display: flex;
//     flex-direction: row;
//     align-items: flex-start;
//     justify-content: flex-start;
//     column-gap: 16px;
//     padding: 16px;

//     .side-content {
//     }

//     .main-content {
//     }
// }
.alerts-page {
    .alert-box-and-cam-view {
        position: relative;
        z-index: -10;

        .alert-box-container {
        }

        .alert-box {
            position: absolute;
            width: 350px;
            bottom: 112%;
            left: -50%;
            z-index: -10;
            box-shadow: 0px 0px 12px 8px rgba(0, 0, 0, 0.2);
            cursor: default;
        }
    }

    .pin {
        position: absolute;
        top: 50%; // Center vertically
        left: 50%; // Center horizontally
        transform: translate(-50%, -116%); // Center the pin exactly at the top of the camera
        z-index: 3; // Ensure pin is on top of everything
    }
}

.alert-main-content {
    // padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .floormap-block {
        height: calc(100vh - 192px);
        position: relative;
        overflow: hidden;
        width: 100%;
        @include flex-column();

        .floormap-container {
            // width: 100%;
            // height: calc(100vh - 212px);
            @include flex-column();
            // align-items: flex-end;
            // max-width: 1024px;
            // width: calc(468px * 512 / 311);

            // Rendered size:	703 × 427 px
            // Rendered aspect ratio:	703∶427
            // Intrinsic size:	1356 × 824 px
            // Intrinsic aspect ratio:	339∶206
            // File size:	354 kB

            // height: calc((100vh + 120px) * 311 / 512);
            height: calc((100vh + 120px) * 206 / 339);
            // height: calc(100vh * 206 / 339);
            aspect-ratio: 339 / 206;
            // height: calc(100vh - 200px);
            // position: relative;
            // width: 100%;

            .zoomable-image-container {
                // .controls {
                //     position: absolute;
                //     bottom: 0px;
                //     right: 0px;
                //     z-index: 10;
                // }
                height: 100%;
                max-width: 100%;
                .zoomable-figure {
                    overflow: visible;
                }
            }
        }
        .controls {
            position: absolute;
            bottom: 16px;
            right: 16px;
            z-index: 10;

            button {
                background-color: #275499 !important;
            }
        }
    }
}

.dialog-content {
    @include flex-row(space-between);
    align-items: flex-start;
    height: calc(100vh - 192px);
    overflow: hidden;
    max-height: 680px;
    column-gap: 16px;
    padding: $padding;

    // .main-content,
    .side-content .expandable-alert-list {
        // background-color: var(--dialogbox-content-bg-color);
        background-color: #3b3a3a;
        border-radius: 4px;
    }

    .side-content {
        width: 340px;
        // overflow-y: auto;
        overflow: hidden;
        height: 100%;
        background-color: #3b3a3a;
        padding: 16px;
        border-radius: 4px;

        .alert-box {
            @include alertBoxTheme(dark);
            padding: 4px 8px;

            hr {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }

        > h4 {
            color: #fbfbfb;
            font-size: $font-size-base;
            font-weight: 500;
            margin: 24px 0 8px;
            padding: 0px 4px;
        }

        .past-alert-container {
            overflow: auto;
            height: calc(100% - 242px);
            @include thin-scrollbar(var(--scrollbar-thumb-color), transparent, var(--scrollbar-thumb-hover-color));
            &::-webkit-scrollbar {
                width: 2px;
            }
            .past-alerts {
                @include flex-column();
                row-gap: 8px;
                width: 100%;
                .alert-info-item {
                    background-color: #222222;
                    padding: 12px 16px;
                    border-radius: 4px;
                    width: 100%;
                    > header {
                        position: relative;
                        > h3 {
                            color: #ffffff;
                            font-size: $font-size-base;
                            font-weight: 500;
                        }

                        mark {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: var(--unresolved-bg);
                            margin-top: 8px;
                            display: inline-block;
                            position: absolute;
                            top: 0;
                            right: 0;

                            &.resolved {
                                background: var(--resolved-bg);
                            }

                            &.unresolved {
                                background: var(--unresolved-bg);
                            }
                        }
                    }

                    > footer {
                        @include flex-row(space-between);
                        > figure {
                            @include flex-row();
                            column-gap: 4px;
                            svg path {
                            }

                            figcaption {
                                font-size: $font-size-small;
                                font-weight: 400;
                                color: #6a778e;
                            }
                        }

                        time {
                            color: #6a778e;
                            font-size: $font-size-xsmall;
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        // .expandable-alert-list {
        //     padding: 16px;
        //     // padding: 0px 16px 16px 16px;
        //     .list-content {
        //         height: 100%;
        //         @include thin-scrollbar(
        //             var(--primary-color),
        //             var(--dialogbox-content-bg-color),
        //             var(--primary-color-dark)
        //         );
        //         // padding: 0px 16px 16px 16px;
        //         // padding: 16px;

        //         > ul li {
        //             .alert-box {
        //                 background: #222222;
        //                 border: 1px solid rgba(0, 0, 0, 0.8);

        //                 .main-heading {
        //                     label {
        //                         color: var(--white-color);
        //                     }
        //                 }

        //                 > footer {
        //                     label,
        //                     time {
        //                         color: #8693a8;
        //                     }
        //                 }
        //             }
        //             &.selected {
        //                 border: 1px solid rgba(50, 83, 220, 1);
        //                 box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
        //             }
        //         }
        //     }
        // }
    }

    .main-content {
        width: calc(100% - 340px);
        color: white;
        height: 100%;
        // @include flex-column();
        // row-gap: 16px;

        // .container {
        //     @include flex-row();
        //     width: 100%;
        //     height: 50%;
        //     height: calc(50% - 8px);
        //     gap: 16px;
        //     list-style: none;
        //     padding: 0;
        //     margin: 0;

        //     &.inner-container {
        //         row-gap: 16px;
        //     }
        //     .item {
        //         @include flex-column();
        //         color: white;
        //         height: 100%;
        //         width: 100%;

        //         .content {
        //             height: 100%;
        //             width: 100%;
        //             position: relative;
        //             .actions {
        //                 position: absolute;
        //                 bottom: 8px;
        //                 right: 8px;
        //                 z-index: 1;

        //                 button {
        //                     background-color: rgba(0, 0, 0, 0.2);
        //                     transition: all 0.3s ease-in-out;

        //                     &:hover {
        //                         background-color: rgba(0, 0, 0, 0.4);
        //                     }
        //                 }
        //             }
        //         }

        //         &.nested {
        //             background: transparent;
        //             > .content {
        //                 @include flex-column();
        //                 row-gap: 16px;
        //                 height: 100%;
        //             }
        //         }

        //         &.zone .content {
        //             .zoomable-image-container {
        //                 background: #222222;
        //                 width: 100%;
        //                 height: 100%;
        //             }
        //         }

        //         &.live-feed,
        //         &.recording,
        //         &.incident-images {
        //             figure {
        //                 width: 100%;
        //                 height: 100%;
        //                 img {
        //                     width: 100%;
        //                     height: 100%;
        //                     object-fit: cover;
        //                     display: block;
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}
